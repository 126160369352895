import React from 'react'
import {Modal} from 'semantic-ui-react'
import PageCommingSoon from "../../shared/error/PageCommingSoon";

const ComingSoon = ({open, closeModal}) => {
    return (
        <Modal
            closeIcon
            open={open}
            size='large'
            closeOnEscape={true}
            closeOnDimmerClick={true}
            onClose={() => closeModal()}
        >
            <Modal.Content>
                <PageCommingSoon />
            </Modal.Content>
        </Modal>
    )
};

export default ComingSoon;