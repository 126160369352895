import React, {useContext, useReducer} from "react";
import reducer from './reducer';
import {PollContext} from './context';

const UsePoll = () => {
    const context = useContext(PollContext);
    if (context === undefined) {
        throw new Error('UsePoll must be used within a Provider')
    }
    const {state, dispatch} = context;
    return [state, dispatch];
};

const PollState = ({children}) => {
    const initialState = {
        loading: false,
        error: {
            enabled: false,
            message: ""
        },
        values: false,
        polls: [],
        poll: {}
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch}
    return (
        <PollContext.Provider value={value}>
            {children}
        </PollContext.Provider>
    );
};

export {PollState, UsePoll};
