import React from 'react'
import {Modal} from 'semantic-ui-react'
import Page401 from "../../shared/error/Page401";

const Unauthorized = ({open, closeModal}) => {
    return (
        <Modal
            closeIcon
            open={open}
            size='large'
            closeOnEscape={true}
            onClose={() => closeModal()}
        >
            <Modal.Content>
                <Page401 />
            </Modal.Content>
        </Modal>
    )
};

export default Unauthorized;