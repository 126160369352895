const salaryEvaluationReducer = (state, action) => {
    switch (action.type) {
        case 'GET_COLLABORATORS_FOR_SALARY_ASSESSMENT': {
            return {...state, candidates: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'GET_SALARIES_ASSESSMENT_BY_COLLABORATOR': {
            return {...state, salariesAssessment: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'GET_SALARIES_ASSESSMENT': {
            return {...state, listSalariesAssessment: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'GET_SUMMARY_SALARY': {
            return {...state, salaryAssessmentSummary: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'GET_SALARY_FUTURE_VALUES_BY_COLLABORATOR': {
            return {...state, futureValues: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'GET_BAND_STRETCH': {
            return {
                ...state,
                currentSection: {...action.payload},
                values: action.values,
                error: {enabled: false, message: ""}
            }
        }
        case 'GET_SALARY_ASSESSMENT': {
            return {...state, salaryAssessment: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'GET_POSITION_VALUES_BY_COLLABORATOR': {
            return {...state, positionValuesByCollaborator: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'ADD_COLLABORATOR_SALARIES_ASSESSMENT': {
            return {...state, salaryAssessment: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'SAVE_SALARY_ASSESSMENT': {
            return {...state, salaryAssessment: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'SAVE_SALARY_PROPOSAL': {
            return {...state, salaryAssessment: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'TERMINATE_SALARIES_ASSESSMENT': {
            return {...state, salaryAssessment: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'UPDATE_SALARY_PROPOSAL': {
            return {...state, salaryAssessment: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'ADD_COLLABORATOR_ANSWER_TO_SALARY_ASSESSMENT': {
            return {...state, salaryAssessment: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'SALARY_ASSESSMENT_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }
        case 'SET_LOADING': {
            return {...state, loading: action.payload}
        }
        case 'GET_SECTIONS': {
            return {...state, sections: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'SAVE_SECTIONS': {
            return {...state, sections: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'DELETE_SECTIONS': {
            return {...state, sections: [...action.payload], error: {enabled: false, message: ""}}
        }
        case 'GET_COLLABORATORS': {
            return {...state, collaborators: [...action.payload], error: {enabled: false, message: ""}}
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

export default salaryEvaluationReducer;
