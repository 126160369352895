import React, {useContext, useReducer} from "react";
import reducer from './reducer';
import {CollaboratorContext} from './context';

const UseCollaborator = () => {
    const context = useContext(CollaboratorContext);
    if (context === undefined) {
        throw new Error('UseCollaborator must be used within a Provider')
    }
    const {state, dispatch} = context;
    return [state, dispatch];
};

const CollaboratorState = ({children}) => {
    const initialState = {
        loading: false,
        error: {
            enabled: false,
            message: ""
        },
        collaborators: [],
        collaborators_available: [],
        salaries: [],
        notes: [],
        values: false,
        collaborator: {},
        courses: [],
        role: {},
        subsidiary: {},
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch}
    return (
        <CollaboratorContext.Provider value={value}>
            {children}
        </CollaboratorContext.Provider>
    );
};

export {CollaboratorState, UseCollaborator};
