import React from "react";
import {Container, Grid, Header, Icon, Image} from "semantic-ui-react";
import backgroundImg from '../../resources/images/release.svg';

const PageCommingSoon = () => {

  return (
    <Grid centered verticalAlign='middle' stackable style={{marginTop: '1rem'}}>
        <Container textAlign='center'>
            <Grid.Row>
                <Grid.Column width={16} style={{marginTop: '5rem'}}>
                    <Header>Pronto estará habilitada esta opción <Icon name="space shuttle" size="small" color="purple" /></Header>
                    <p>Nuestra plataforma incorporará la opción que tanto buscas, te notificaremos cuando este listo.</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16} style={{marginTop: '5rem'}}>
                    <Image src={backgroundImg} centered />
                    <br/>
                </Grid.Column>
            </Grid.Row>
        </Container>
    </Grid>
  );
};

export default PageCommingSoon;
