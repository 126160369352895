import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Container, Divider, Grid, Header, Image, Segment} from 'semantic-ui-react'
import './Login.css';
import * as Sentry from "@sentry/browser";
import bgLogin from './../../resources/images/bg-login.png';
import logoPolaris from './../../resources/images/polaris-login.svg';
import google from './../../resources/images/google.svg';
import {UseLogin} from '../../store/oauth/state';
import {authenticate, login} from "../../store/oauth/action";
import useCheckMobileScreen from "../../shared/hooks/useCheckMobileScreen";
import Unauthorized from "./Unauthorized";
import ComingSoon from "./ComminSoon";
import {removeToken, setRefreshToken, setToken, removeRefreshToken, setAccessTokenGoogle, removeAccessTokenGoogle} from "../../shared/helper/Auth";

function Login() {
    const navigate = useNavigate();
    const [state, dispatch] = UseLogin();
    const search = useLocation().search;
    const isMobile = useCheckMobileScreen();
    const [showUnauthorized, setShowUnauthorized] = useState(false);
    const [showComingSoon, sideshowComingSoon] = useState(false);
    const handleAuthenticate = () => authenticate();
    const handleLogin = async () => {
        await login(dispatch);
        const redirectUrl = localStorage.getItem('redirectUrl');

        if(redirectUrl) {
            navigate(`${redirectUrl}`);
        } else {
            navigate('/home');
        }
    }
    useEffect(() => {
        removeToken();
        removeRefreshToken();
        removeAccessTokenGoogle();
        if (process.env.NODE_ENV === 'production') {
            Sentry.configureScope(scope => scope.setUser(null));
        }
        // get error when login failed
        const qpError = new URLSearchParams(search).get("error");
        const qpToken = new URLSearchParams(search).get("token");
        const rfToken = new URLSearchParams(search).get("refreshToken");
        const accessTokenGoogle = new URLSearchParams(search).get("access_token_google");
        if (qpError !== null) {
            showUnauthorizedModal();
        }
        if (qpToken !== null) {
            setToken(qpToken);
            setRefreshToken(rfToken);
            setAccessTokenGoogle(accessTokenGoogle);
            handleLogin();
        }
    }, []);

    const showUnauthorizedModal = () => setShowUnauthorized(true);
    const hideUnauthorizedModal = () => setShowUnauthorized(false);
    const showComingSoonModal = () => sideshowComingSoon(true);
    const hideComingSoonModal = () => sideshowComingSoon(false);

    return (
        <>
            <Unauthorized open={showUnauthorized} closeModal={hideUnauthorizedModal}/>
            <ComingSoon open={showComingSoon} closeModal={hideComingSoonModal}/>
            <Grid>
                {!(isMobile) && (
                    <Grid.Column width={10} style={{marginTop: '-20px', padding: 0}}>
                        <div style={{
                            backgroundImage: `url(${bgLogin})`,
                            backgroundSize: 'cover',
                            height: '100%',
                            minHeight: '100vh'
                        }}>
                            <Divider hidden/>
                        </div>
                    </Grid.Column>
                )}
                <Grid.Column width={isMobile ? 16 : 6} style={isMobile ? {} : {margin: 'auto',  padding: '0px'}}
                             floated='left'>
                    <Segment style={isMobile ? {
                        boxShadow: 'inherit',
                        border: 'inherit',
                        minHeight: '100vh'
                    } : {minWidth: '478px', marginRight:'2.29rem'}}>
                        <Container textAlign="center">
                            <Image src={logoPolaris} centered style={{marginBottom: '2em'}}/>
                            <Header size='large' style={{fontWeight: 'bold', margin: '0px', padding: '0px', marginBottom: '1em'}}>Bienvenido
                                maker!</Header>
                            <p style={{fontSize: '1.3rem', margin: '0px', padding: '0px', marginBottom: '1em'}}>Inicia Sesión con tu cuenta y
                                encuentra información detallada de recursos y personas</p>
                            <Button style={{
                                fontSize: '1.3rem',
                                borderRadius: '1rem',
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                marginBottom: '2em'
                            }} basic size='medium' fluid onClick={handleAuthenticate}>
                                <Image src={google} avatar/>
                                Continua con Google
                            </Button>
                            <p>@Derechos reservados 2022</p>
                        </Container>
                    </Segment>
                </Grid.Column>
            </Grid>
        </>
    );
}

const LoginEnvelope = () => {
    return (
        <>
            <Login/>
        </>
    );
}

export default LoginEnvelope;
