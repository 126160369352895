import React, { useEffect } from "react";
import { gifFile } from "../../resources/gifs";
import './PolarisDropzoneFile.css';
import { CheckmarkOutline, Close, CloseOutline } from "@carbon/icons-react";

export const PolarisDropzoneFile = ({ file, setFile, accept = '*', percentage = 0 }) => {

  useEffect(() => {
    const $percentage = document.getElementById('percentage');
    if(percentage > 0) $percentage.style.width = `${percentage}%`;
  }, [percentage])

  const handleChange = ({ target }) => {
    setFile(target.files[0]);
  }

  const onDrop = (event) => {
    event.preventDefault();
    if(event?.dataTransfer?.files[0].type.split('/')[1] === 'pdf') setFile(event?.dataTransfer?.files[0]);
  }

  return (
    <div className="container-dropzone-upload-file">
      {
        file?.name ? (
          <>
            <img src={gifFile} alt="gif of file"/>
            <h4 style={{ margin: 0 }}>1 archivo seleccionado</h4>
            <div className="detail-file">
              <p style={{ fontSize: '11px', margin: 0 }}>{file?.name}</p>
              <p style={{ fontSize: '10px', margin: 0, color: '#686868' }}>{file?.size < 1000 ? `${file?.size} bytes` : file?.size < 1000000 ? `${parseInt(file?.size / 1000)} kb` : `${parseFloat(file?.size / 1000000).toFixed(1)} mb`}</p>
              { percentage === 0 && <Close className="delete-file" size={20} onClick={() => setFile({})}/>}
            </div>
            {
              (percentage > 0 || percentage === -1) && (
                <div className="upload-percentage">
                  <div id='percentage' className="bar-percentage" style={{ backgroundColor: percentage === -1 ? '#FF5256' : '#21BA45' }}></div>
                  <p style={{ display: 'flex', alignItems: 'center', gap: '.5rem', padding: '.5rem' }}>
                    
                    {
                      percentage === -1 ? (
                        <>
                          <CloseOutline color="#FF5256" size={14} />
                          Falló la carga del archivo, inténtelo de nuevo o contacte a servicio técnico
                        </>
                      ) : (
                        <>
                          <CheckmarkOutline color="#21BA45" size={14} />
                          {percentage} %
                        </>
                      )
                    }
                  </p>
                </div>
              )
            }
          </>
        ) : (
          <>
            <label htmlFor="dropzone-upload-file" className="dropzone-upload-file" onDrop={onDrop} onDragOver={(e) => e.preventDefault()} onDragEnd={(e) => e.preventDefault()}>Suelta el archivo aquí o examina en tus <br></br>archivos locales<p className="text-accept">Sólo archivos PDF</p></label>
            <input id="dropzone-upload-file" type="file" hidden accept={accept} onChange={handleChange} />
          </>
        )
      }
    </div>
  )
}