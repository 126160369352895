const loginReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN': {
            return {...state, userConnected: {...action.payload?.personal_information}, accessToken: action.payload?.access_token, scopes: action.payload?.scopes,  error: {enabled: false, message: ""}}
        }
        case 'LOGIN_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }
        case 'SET_LOADING': {
            return {...state, loading: action.payload}
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

export default loginReducer;
