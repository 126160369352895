import React from 'react';
import { Popup } from 'semantic-ui-react';
import './PolarisPopup.css';

const PolarisPopup = ({ children, styles, ...props }) => (
  <Popup
    {...props}
    trigger={children}
    basic
    style={{
      backgroundColor: '#FAF0CA',
      color: '#000000',
      fontWeight: '300',
      ...styles
    }}
  >
    {props?.title && <Popup.Header>{props?.title}</Popup.Header>}

    <Popup.Content>
      <>{props?.html}</>
    </Popup.Content>
  </Popup>
);

export default PolarisPopup;
