import React, {useContext, useReducer} from "react";
import reducer from './reducer';
import {SalaryAssessment} from './context';

const UseSalaryAssessment = () => {
    const context = useContext(SalaryAssessment);
    if (context === undefined) {
        throw new Error('UseSalaryEvaluation must be used within a Provider')
    }
    const {state, dispatch} = context;
    return [state, dispatch];
};

const SalaryAssessmentState = ({children}) => {
    const initialState = {
        loading: false,
        error: {
            enabled: false,
            message: ""
        },
        candidates: [],
        salaryAssessment: null,
        listSalariesAssessment: [],
        salaryAssessmentSummary: null,
        sections: [],
        currentSection: null,
        collaborators: [],
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch}
    return (
        <SalaryAssessment.Provider value={value}>
            {children}
        </SalaryAssessment.Provider>
    );
};

export {SalaryAssessmentState, UseSalaryAssessment};
