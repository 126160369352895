
const CONSTANT = {
    auth: 'auth',
    refresh: 'refresh',
    accessTokenGoogle: 'access_token_google'
};

const setToken = (token) => {
    window.localStorage.setItem(CONSTANT.auth, JSON.stringify({token}));
}
const setRefreshToken = (token) => {
    window.localStorage.setItem(CONSTANT.refresh, JSON.stringify({token}));
}
const setAccessTokenGoogle = (accessToken) => {
    window.localStorage.setItem(CONSTANT.accessTokenGoogle, JSON.stringify({accessToken}));
}

const removeToken = () => {
    window.localStorage.removeItem(CONSTANT.auth);
}
const removeRefreshToken = () => {
    window.localStorage.removeItem(CONSTANT.refresh);
}
const removeAccessTokenGoogle = () => {
    window.localStorage.removeItem(CONSTANT.accessTokenGoogle);
}

const getToken = (type) => {
    const item = window.localStorage.getItem(type);
    if (typeof item === undefined) return undefined;
    return JSON.parse(item)?.token;
}
const getAccessTokenGoogle = () => {
    const item = window.localStorage.getItem('access_token_google');
    if (typeof item === undefined) return undefined;
    return JSON.parse(item)?.accessToken;
}

export {
    setToken, getToken, removeToken, setRefreshToken, removeRefreshToken, getAccessTokenGoogle, setAccessTokenGoogle, removeAccessTokenGoogle
}