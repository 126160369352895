import React from "react";
import {Container, Grid, Header, Image} from "semantic-ui-react";
import backgroundImg from '../../resources/images/page-available.svg';

const Page401 = () => {

  return (
          <Grid centered verticalAlign='middle' stackable>
              <Container textAlign='center'>
                  <Grid.Row>
                      <Grid.Column width={16} style={{marginTop: '5rem'}}>
                          <Header size="huge">Oops!</Header>
                          <Header size="medium">Esta cuenta no tiene permitido ingresar</Header>
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                      <Grid.Column width={16}>
                          <Image src={backgroundImg} centered />
                          <br/>
                      </Grid.Column>
                  </Grid.Row>
              </Container>
          </Grid>
  );
};

export default Page401;