import React, { useEffect, useState } from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import { getSASTokenForCreate } from "../../repository/azure";
import { BlobServiceClient } from '@azure/storage-blob';
import { UseLogin } from "../../store/oauth/state";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./FileUploadModal.css";
import { PolarisDropzoneFile } from "../../shared/component/PolarisDropzoneFile";
import Alert from "../../shared/component/Alert";

export const FileUploadModal = ({ blobs, setBlobs }) => {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [file, setFile] = useState({});
  const [percentage, setPercentage] = useState(0);

  const [stateLogin] = UseLogin();

  useEffect(() => {
    if(file?.name) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [file])
  
  useEffect(() => {
    setPercentage(0)
    setFile({})
  }, [open])

  const save = async() => {
    try {
      setLoading(true)
      const { data } = await getSASTokenForCreate();
      const blobServiceClient = new BlobServiceClient(`${data.url}${data.sasToken}`, );
      const containerClient = blobServiceClient.getContainerClient(data.container);

      const date = new Date();
      const type = file.type.split('/');
      const fileName = `Bases del programa de Referidos Versión ${date.getTime()}.${type[1]}`;
      const blockBlobClient = containerClient.getBlockBlobClient(`${date.getFullYear()}/${fileName}`);
      const blockSize = 1 * 1024 * 1024;
      const currentSize = file.size;
      const metadata = {
        names: stateLogin.userConnected.names,
        lastnames: stateLogin.userConnected.lastnames,
        nin: stateLogin.userConnected.nin,
        status: 'Nuevo'
      };
      
      await blockBlobClient.uploadData(file, {
        blockSize: blockSize,
        concurrency: 20,
        metadata,
        onProgress: async(ev) => {
          const resPercentage = (ev.loadedBytes/currentSize) * 100;
          setPercentage(resPercentage.toFixed(2));
          if(resPercentage === 100) {
            setBlobs([
              ...blobs,
              {
                name: `${date.getFullYear()}/${fileName}`,
                properties: {
                  createdOn: new Date().toISOString()
                },
                metadata: {
                  ...metadata,
                  status: 'Nuevo'
                },
                versionId: new Date().getTime()
              }
            ])
            setLoading(false)
            setPercentage(100)
            setTimeout(() => {
              setOpen(false)
              Alert.getSweetAlertTop('Archivos cargados exitosamente', 'success');
            }, 2000)
          }
        }
      });
    } catch (error) {
      setLoading(false)
      setPercentage(-1)
    }
  }

  return (
    <Modal
      className="file-upload-modal-program-bases"
      onClose={() => setOpen(loading)}
      onOpen={() => setOpen(true)}
      open={open}
      size='tiny'
      trigger={
        <Button style={{ backgroundColor: 'var(--color-primary)', color: '#fff' }} id='POLReferralsOpenModalFileUpload'>
          Cargar archivo nuevo
        </Button>
      }
    >
      <Modal.Header>
        <p style={{ fontWeight: 400, fontSize: '20px', textAlign: 'center' }}>Cargar archivo nuevo</p>
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row style={{ justifyContent: 'center' }}>
            <PolarisDropzoneFile
              file={file}
              setFile={setFile}
              accept=".pdf"
              percentage={percentage}
            />
          </Grid.Row>
          <Grid.Row style={{ justifyContent: 'center', gap: '2rem', marginTop: '1rem' }}>
            {
              (percentage === 0 || percentage === -1) && (
                <>
                  { !loading && <Button style={{ backgroundColor: 'transparent' }} onClick={() => setOpen(false)}>Cancelar</Button> }
                  <Button className="btn-save-file-upload-modal-program-bases" disabled={disabledButton || loading} onClick={save} color='green'>Cargar archivo</Button>
                </>
              )
            }
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}