import React from "react";
import { Button, Grid, Image } from "semantic-ui-react";
import { IconIMGrey, ImageMoneyMan } from "../../resources/images";

const MyReferrals = () => {
  
    return (
        <Grid>
            <Grid.Row style={{ justifyContent: 'flex-end' }}>
                <Grid.Column style={{ width: 'auto' }}>
                  <Button style={{ backgroundColor: 'var(--color-information)', color: 'var(--color-primary)', fontFamily: 'Montserrat, Lato' }}>Características del programa</Button>
                </Grid.Column>
                <Grid.Column style={{ width: 'auto' }}>
                  <Button style={{ backgroundColor: 'var(--color-primary)', color: 'white', fontFamily: 'Montserrat, Lato' }}>Nuevo referido</Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="my-referrals-content">
                <Image src={ImageMoneyMan}/>
                <h2 style={{ fontWeight: 600, fontSize: '24px' }} >¡Crezcamos juntos!</h2>
                <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gap: '4px'}}>Programa referidos <Image style={{ marginTop: '2px'}} src={IconIMGrey} /></p>
                <p style={{ fontSize: '12px' }} >¡Por cada referido contratado que tengas puedes ganar un dinero extra en tu pago del mes!</p> 
            </Grid.Row>
        </Grid>
    );
}

export default MyReferrals;