import axios from 'axios';
import Alert from "../../shared/component/Alert";
import {getToken, setToken} from "../../shared/helper/Auth";


const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:4010'
})

// avoid modal error
const instanceUncontrolled = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:4010'
});

instance.interceptors.request.use((config)=>{
    config.headers.Authorization = `Bearer ${getToken('auth')}`;
    return config;
    },(error)=>{
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await instance.get("/refresh", {
                headers: {
                    'refreshToken': getToken('refresh'),
                }
            });
            const accessToken = rs.data.accessToken;
            setToken(accessToken);
            instance.defaults.headers.Authorization = `Bearer ${accessToken}`;
            return instance(originalConfig);
          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
            Alert.checkHttpErrorResponse(_error);
            return Promise.reject(_error);
          }
        }
      }
      Alert.checkHttpErrorResponse(err);
      return Promise.reject(err);
    }
  );
instanceUncontrolled.interceptors.request.use((config)=>{
        config.headers.Authorization = `Bearer ${getToken('auth')}`;
        return config;
    },(error)=>{
        return Promise.reject(error);
    }
);

instanceUncontrolled.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await instance.get("/refresh", {
                headers: {
                    'refreshToken': getToken('refresh'),
                }
            });
            const accessToken = rs.data.accessToken;
            setToken(accessToken);
            instance.defaults.headers.Authorization = `Bearer ${accessToken}`;
            return instance(originalConfig);
          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
            Alert.checkHttpErrorResponse(_error);
            return Promise.reject(_error);
          }
        }
      }
      Alert.checkHttpErrorResponse(err);
      return Promise.reject(err);
    }
  );

export {instance, instanceUncontrolled};


