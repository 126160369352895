import React from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Button, Container, Grid, Header, Icon, Image} from "semantic-ui-react";
import backgroundImg from '../../resources/images/page-404.svg';

const Page404 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const backToHome = () => {
    navigate('/collaborators');
  };

  return (
    <Grid centered verticalAlign='middle' stackable>
        <Container textAlign='center'>
            <Grid.Row>
                <Grid.Column width={16} style={{marginTop: '5rem'}}>
                    <Header>P&aacute;gina No Encontrada <Icon name="bell" size="small" color="yellow" /></Header>
                    <p>La url solicitada {location.pathname} no fue encontrada en el sistema</p>
                    <Button onClick={backToHome} primary>Regresar a Home</Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16} style={{marginTop: '5rem'}}>
                    <Image src={backgroundImg} centered />
                </Grid.Column>
            </Grid.Row>
        </Container>
    </Grid>
  );
};

export default Page404;