const pollReducer = (state, action) => {
    switch (action.type) {
        case 'GET_POLLS': {
            return {...state, polls: [...action.payload], error: {enabled: false, message: ""}, values: action.values}
        }
        case 'GET_POLLS_BY_ID': {
            return {...state, poll:  {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'SAVE_POLL': {
            return {...state, poll: {[action.key]: {...action.payload}}, error: {enabled: false, message: ""}}
        }
        case 'SEND_POLL': {
            return {...state, poll: {[action.key]: {...action.payload}}, error: {enabled: false, message: ""}}
        }
        case 'PUT_POLL': {
            return {...state, collaborator: {[action.key]: {...action.payload}}, error: {enabled: false, message: ""}}
        }
        case 'SET_ANSWERS_POLL_BY_ID': {
            return {...state, collaborator: {[action.key]: {...action.payload}}, error: {enabled: false, message: ""}}
        }
        case 'POLL_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }
        case 'SET_LOADING': {
            return {...state, loading: action.payload}
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

export default pollReducer;
