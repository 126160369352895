import Swal from 'sweetalert2';
import warningIcon from '../../resources/images/warningIcon.gif';
import errorIcon from '../../resources/images/errorIcon.gif';

const PolarisAlert = async (options) => {
  const result = await Swal.fire({
    ...options,
    confirmButtonColor: '#21BA45',
    dangerButtonColor: '#cb2535',
    cancelButtonColor: '#fff',
    customClass: {
      popup: 'polaris-alert',
      confirmButton: 'polaris-alert-confirm-button',
      cancelButton: 'polaris-alert-cancel-button',
      title: 'polaris-alert-title',
      htmlContainer: 'polaris-alert-html-container',
    },
  });
  return result.value;
};

const buildIconHtml = (icon) => {
  return `<img src="${icon}" width='156px' style='margin: 0.2em 0em 1em 0em' />`;
};

const PolarisAlertError = (options) => {
  return PolarisAlert({
    ...options,
    type: 'error',
    padding: '2.5em',
    confirmButtonText: 'Cerrar',
    title: 'Problemas para realizar la operación',    
    iconHtml: buildIconHtml(errorIcon),
  });
};

const PolarisAlertWarning = (options) => {
  return PolarisAlert({
    ...options,
    width: 600,
    type: 'warning',
    padding: '2.5em',
    confirmButtonText: 'Cerrar',
    title: 'Problemas para realizar la operación',
    iconHtml: buildIconHtml(warningIcon),
  });
};

const PolarisAlertLoading = (options) => {
  return PolarisAlert({
    ...options,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
};

const PolarisAlertClose = () => {
  Swal.close();
};

export {
  PolarisAlert,
  PolarisAlertWarning,
  PolarisAlertError,
  PolarisAlertLoading,
  PolarisAlertClose,
};
