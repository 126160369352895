import * as Repository from "../../repository/oauth";

const setLoading = (dispatch, status) =>
    dispatch({type: "SET_LOADING", payload: status});

const authenticate = async () =>
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/oauth/google`;

const login = async (dispatch) => {
    setLoading(dispatch, true);
    return new Promise((resolve, reject) => Repository.login().then(async (result) => {
        await dispatch({type: 'LOGIN', payload: result.data})
        resolve(result.data);
    }).catch(async (error) => {
        await dispatch({type: 'LOGIN_ERROR', payload: error})
        console.log(`Has ocurred an error, check de console ${error}`);
        reject(error);
    })).finally(() => setLoading(dispatch, false));
}

export {login, authenticate, setLoading};
