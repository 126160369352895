import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Checkbox, Grid, Modal } from "semantic-ui-react";
import { PolarisDataGrid } from "../../shared/component/PolarisDataGrid";
import { Column } from "devextreme-react/data-grid";
import { TrashCan, Download } from "@carbon/icons-react";
import { FileUploadModal } from "./FileUploadModal";
import { getBlobs, getSASTokenForDelete, getSASTokenForModify, getSASTokenForRead } from "../../repository/azure";
import { BlobServiceClient } from '@azure/storage-blob';
import Alert from "../../shared/component/Alert";
import { DateTime } from "luxon";
import { ImageMenInWaiting } from "../../resources/images";
const TIME_ZONE = 'America/Santiago';

export const ProgramBases = () => {

  const [blobs, setBlobs] = useState([]);
  const [blobSelected, setBlobSelected] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [fileName, setFileName] = useState('');

  const datagridRef = useRef(null)

  const getFilesOfProgramBases = useCallback(async() => {
    const { data } = await getBlobs();
    setBlobs(data)
  }, [])

  useEffect(() => {
    getFilesOfProgramBases()
  }, [getFilesOfProgramBases])

  useEffect(() => {
    const blobActive = blobs.find((e) => e.metadata?.status === 'Vigente');
    setBlobSelected(blobActive?.versionId)
  }, [blobs])

  useEffect(() => {
    if(!open) {
      const blobActive = blobs.find((e) => e.metadata?.status === 'Vigente');
      setBlobSelected(blobActive?.versionId)
      setFileName('')
    }
  }, [open])

  const customColumns = (data) => data.map((dataItem) => {
    const blobName = dataItem.name.split('/');
    dataItem._name = blobName[1];
    dataItem.created_by = dataItem.metadata?.lastnames ? `${dataItem.metadata?.names} ${dataItem.metadata?.lastnames}` : 'Sin registro';
    dataItem.created_at = DateTime.fromISO(dataItem.properties.createdOn, {zone: TIME_ZONE})
      .setLocale('es-ES')
      .toJSDate()
      .toLocaleString(DateTime.DATETIME_FULL);
    dataItem.status = dataItem.metadata?.status;
    return dataItem;
  })
  
  const showModalActiveFile = (value) => {
    setBlobSelected(value)
    setOpen(true)
  }

  const showConfirmationDelete = (versionId) => {
    const blob = blobs.find((e) => e.versionId === versionId);
    setFileName(blob.name);
    setOpen(true)
  }

  const buildCheckboxActive = (item) => (
    <Checkbox
      className="checkbox-active-file-program-bases"
      name='groupActiveFileProgramBases'
      value={item.data?.versionId}
      checked={blobSelected === item.data?.versionId}
      onChange={(e, { value }) => showModalActiveFile(value)}
      disabled={item.data?.metadata?.status !== 'Nuevo'}
    />
  );

  const deleteFile = async() => {
    try {
      setLoading(true)
      
      const { data } = await getSASTokenForDelete();
      const blobServiceClient = new BlobServiceClient(`${data.url}${data.sasToken}`);
      const containerClient = blobServiceClient.getContainerClient(data.container);
      
      const indexBlob = blobs.findIndex((e) => e.name === fileName);
      const blobClient = containerClient.getBlockBlobClient(blobs[indexBlob].name);
      await blobClient.delete();

      const refreshBlobs = [ ...blobs ];
      refreshBlobs.splice(indexBlob, 1)
      setBlobs(refreshBlobs);
      Alert.getSweetAlertTop(
        'Se ha eliminado satisfactoriamente',
        'success'
      );
      setLoading(false)
      setOpen(false)
      setFileName('');
    } catch (error) {
      setOpen(false)
      setLoading(false)
      console.log(error);
      Alert.getSweetAlertTop(
        'Error',
        'error'
      );
      setFileName('');
    }
  }

  const downloadFile = async(versionId, fileName) => {
    if(!downloading) {
      setDownloading(true)
      const { data } = await getSASTokenForRead();
      const blobServiceClient = new BlobServiceClient(`${data.url}${data.sasToken}`);
      const containerClient = blobServiceClient.getContainerClient(data.container);

      const indexBlob = blobs.findIndex((e) => e.versionId === versionId);
      const blobClient = containerClient.getBlockBlobClient(blobs[indexBlob].name);
      const blob = (await blobClient.download());
      const stream = await blob.blobBody;
      const blobData = await new Response(stream).blob();
      const downloadUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      link.click();
      setDownloading(false)
    }
  };

  const buildIIdLinkShow = (item) => (
    <div align="center" style={{ display: 'flex', justifyContent: 'center', gap: '.5rem' }}>
      { item.data.metadata?.status === 'Nuevo' && <TrashCan onClick={() => showConfirmationDelete(item.data.versionId)} size={24} cursor='pointer' /> }
      <Download onClick={() => downloadFile(item.data.versionId, item.data._name)} size={24} cursor={downloading ? 'default' : 'pointer'} />
    </div>
  );

  const activeFile = async() => {
    try {
      setLoading(true);
      const { data } = await getSASTokenForModify();
      const blobServiceClient = new BlobServiceClient(`${data.url}${data.sasToken}`);
      const containerClient = blobServiceClient.getContainerClient(data.container);

      const indexBlobToActivate = blobs.findIndex((e) => e.versionId === blobSelected);
      const blobClientToActivate = containerClient.getBlockBlobClient(blobs[indexBlobToActivate].name);
      const propertiesToActivate = await blobClientToActivate.getProperties();

      const refreshBlobs = [ ...blobs ];

      const indexBlobActive = blobs.findIndex((e) => e.metadata?.status === 'Vigente');
      if(indexBlobActive >= 0) {
        const blobClientActive = containerClient.getBlockBlobClient(blobs[indexBlobActive].name);
        const propertiesActive = await blobClientActive.getProperties();

        await Promise.all([
          await blobClientToActivate.setMetadata({ ...propertiesToActivate.metadata, status: 'Vigente' }),
          await blobClientActive.setMetadata({ ...propertiesActive.metadata, status: 'Obsoleto' })
        ])
        refreshBlobs[indexBlobActive] = {
          ...blobs[indexBlobActive],
          metadata: { ...propertiesActive.metadata, status: 'Obsoleto' }
        };
      } else {
        await blobClientToActivate.setMetadata({ ...propertiesToActivate.metadata, status: 'Vigente' });
      }
      refreshBlobs[indexBlobToActivate] = {
        ...blobs[indexBlobToActivate],
        metadata: { ...propertiesToActivate.metadata, status: 'Vigente' }
      };
      setBlobs(refreshBlobs);
      setOpen(false);
      Alert.getSweetAlertTop(
        'Nueva base del programa activada',
        'success'
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Alert.getSweetAlertTop(
        'Error',
        'error'
      );
    }
  };

  return (
    <>
      <Grid>
        <Grid.Row style={{ justifyContent: 'flex-end' }}>
          <FileUploadModal blobs={blobs} setBlobs={setBlobs} />
        </Grid.Row>
        <Grid.Row>
          {
            blobs?.length > 0 ? (
              <PolarisDataGrid
                ref={datagridRef}
                data={customColumns(blobs)}
                className='datagrid-program-bases-referrals'
                id='DataGrid_Program_Bases_Referrals'
                keyExpr='versionId'
                storageKey='DataGrid_Program_Bases_Referrals'
                fileName='DataGrid_Program_Bases_Referrals'
                columns={[
                  <Column caption="Activar" dataField="versionId" dataType='boolean' allowExporting={false} allowFiltering={false} visible={true} cellRender={buildCheckboxActive} width={100} />,
                  <Column caption="Nombre del archivo" dataField="_name" dataType='string' visible={true}/>,
                  <Column caption="Fecha de incorporación" dataField="created_at" dataType='date' visible={true} format={'yyyy-MM-dd HH:mm:ss'} defaultSortOrder="asc"/>,
                  <Column caption="Incorporado por" dataField="created_by" dataType='string' visible={true}/>,
                  <Column caption="Estado" dataField="status" dataType='string' visible={true}/>,
                  <Column caption="Acciones" dataField="actions" allowExporting={false} allowFiltering={false} visible={true} width={100} cellRender={buildIIdLinkShow}/>
                ]}
              />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '2rem', marginTop: '5rem' }}>
                <img src={ImageMenInWaiting} alt="Men in waiting" />
                <p style={{ textAlign: 'center', color: 'var(--color-secondary)', fontWeight: 600 }}>Aún no tenemos información en esta sección, <br />pero pronto llegará</p>
              </div>
            )
          }
        </Grid.Row>
      </Grid>
      <Modal
        className="active-file-program-bases"
        onClose={() => setOpen(false)}
        open={open}
        size='tiny'
      >
        <Modal.Header>
          <p style={{ fontWeight: 400, fontSize: '20px', textAlign: 'center' }}>{ fileName !== '' ? 'Eliminar archivo' : 'Activación de base del programa' }</p>
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row style={{ padding: '1rem 3rem' }}>
              { 
                fileName !== '' ? (
                  <>
                    <p>El archivo <strong>{ fileName.split('/')[1] }</strong> será eliminado de la plataforma sin posibilidad de recuperación.</p>
                    <p>¿Desea eliminar el archivo?</p>
                  </> 
                ) : (
                  <>
                    <p>Al presionar el botón <strong>“activar”</strong>, el archivo seleccionado pasará a ser el vigente del programa y el archivo anterior quedará obsoleto. (El archivo obsoleto no podrá ser activado nuevamente)”</p>
                    <p>¿Desea activar el archivo?</p>
                  </>
                )
              }
            </Grid.Row>
            <Grid.Row style={{ justifyContent: 'center', gap: '2rem', marginTop: '1rem' }}>
              { 
                !loading && (
                  <>
                    <Button style={{ backgroundColor: 'transparent' }} onClick={() => setOpen(false)}>{ fileName !== '' ? 'Cancelar' : 'No, cancelar' }</Button>
                    <Button style={{ padding: '11px 3rem' }} onClick={fileName !== '' ? deleteFile : activeFile} color={loading ? '#D0CFCF' : 'green'}>{ fileName !== '' ? 'Aceptar' : 'Si, activar' }</Button>
                  </>
                ) 
              }
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    </>
  )
}