import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import 'devextreme/dist/css/dx.light.css';
import reportWebVitals from './reportWebVitals';
import App from "./features/app/App";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import CustomTheme from './theme'

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        environment: window.location.hostname,
        attachStacktrace: true,
        release: "mfe-polaris@" + process.env.REACT_APP_VERSION,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    });
}

ReactDOM.render(
    <ChakraProvider theme={CustomTheme}>
        <CSSReset />
        <App/>
    </ChakraProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
