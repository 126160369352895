import React, {useState} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import './App.css';
import Login from "../login/Login";
import LazyComponent from "../../shared/component/LazyComponent";
import Page404 from "../../shared/error/Page404";
import {PollState} from "../../store/poll/state";
import {LoginState} from "../../store/oauth/state";
import {ProjectState} from "../../store/project/state";
import {SalaryAssessmentState} from "../../store/salary-assessment/state";
import Referrals from '../referrals/Referrals';
import { CollaboratorState } from '../../store/collaborator/state';

const Home  = React.lazy(() => import("../home/Home"));
const AddCollaborator = React.lazy(() => import("../collaborator/add/AddCollaborator"));
const Collaborators = React.lazy(() => import("../collaborator/Collaborators"));
const AppContainer = React.lazy(() => import("../../shared/container-app/app-container"));
const AddRetention = React.lazy(() => import("../retention/add/AddRetention"));
const Retentions = React.lazy(() => import("../retention/retentions"));
const PageCommingSoon = React.lazy(() => import("../../shared/error/PageCommingSoon"));
const ConceptForm = React.lazy(() => import("../../shared/final-form/FinalForm"));
const TalentPool = React.lazy(() => import("../talent-pool/TalentPool"));
const TalentPoolDetail = React.lazy(() => import("../talent-pool/talent-pool-detail/TalentPoolDetail"));
const AddPoll = React.lazy(() => import("../poll/add/AddPoll"));
const ShowPoll = React.lazy(() => import("../poll/showPoll/ShowPoll"));
const ShowAnswerPoll = React.lazy(() => import("../poll/showAnswerPoll/showAnswerPoll"));
const Poll = React.lazy(() => import("../poll/Poll"));
const AddMany = React.lazy(() => import("../collaborator/add-many/AddMany"));
const MakerTrip = React.lazy(() => import("../maker-trip/Maker-Trip"));
const MakerHome = React.lazy(() => import("../maker-home/MakerHome"));
const PageSuccessPoll = React.lazy(() => import("../poll/PageSuccessPoll"));
const Session = React.lazy(() => import("./Session"));
const MakerNotificationAlertListEnvelop = React.lazy(() => import("../maker-home/MakerNotificationAlertList/MakerNotificationAlertList"));
const DetailRetention = React.lazy(() => import("../retention/DetailRetention/DetailRetention"));
const DetailAccountEnvelop = React.lazy(() => import("../account/DetailAccount"));
const CandidateList = React.lazy(() => import("../salary-assesment/candidate-list/CandidateList"));
const SalaryAssessmentDetail = React.lazy(() => import("../salary-assesment/salary-assesment-detail/SalaryAssessmentDetail/SalaryAssessmentDetail"));
const DetailProject = React.lazy(() => import("../account/projects/detailProject/DetailProject"));
const DetailNewBusinessAccount = React.lazy(() => import("../account/new-business/DetailNewBusiness"));
const DetailNewBusiness = React.lazy(() => import("../business-admin/new-business/new-business-detail/DetailNewBusiness"));
const SalaryProposal = React.lazy(() => import("../salary-assesment/salary-proposal/SalaryProposal"));
const Accounts = React.lazy(() => import("../account/Accounts"));
const SalaryAssessmentEnvelop = React.lazy(() => import("../salary-assesment/SalaryAssesment"));
const Settings = React.lazy(() => import("../settings/Settings"))
const Business = React.lazy(() => import("../business-admin/business-admin"))

function App() {
    const state = {
        nav: [["/", "Home"]]
    }
    const AddToBreadCrumbs = (path, displayName) => {
        useState({nav: [...state.nav, [path, displayName]]})
    }

    const RollBackBreadCrumbs = (destination, event) => {
        console.log("roll back to", destination);
        useState({nav: state.nav.slice(0, destination + 1)});
        event.preventDefault();
        console.log("hadle");

    }

    return (
        <LoginState>
            <Router>
                <Routes>
                    <Route path="/home" element={<LazyComponent><Session role={"home"}><AppContainer><Home
                        RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/settings" element={<LazyComponent><Session role={"settings"}><AppContainer><Settings
                        RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/business-admin" element={<LazyComponent><Session role={"settings"}><AppContainer><Business
                        RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/collaborators/add"
                           element={<LazyComponent><Session role={"collaborator::write"}><AppContainer><AddCollaborator
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/collaborators"
                           element={<LazyComponent><Session role={"collaborator::read"}><AppContainer><Collaborators
                               RollBackBreadCrumbs={RollBackBreadCrumbs} role={"collaborator::read"} /></AppContainer></Session></LazyComponent>}/>
                    <Route path="/collaborators/add-many"
                           element={<LazyComponent><Session role={"collaborator::write"}><AppContainer><AddMany
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/collaborators/:id"
                           element={<LazyComponent><Session role={"collaborator::write"}><AppContainer><AddCollaborator
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/maker-trip"
                           element={<LazyComponent><Session role={"maker-trip"}><AppContainer><MakerTrip
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/talent-pool"
                           element={<LazyComponent><Session role={"talent-pool::read"}><AppContainer><TalentPool
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/talent-pool/:id"
                           element={<LazyComponent><Session role={"talent-pool::read"}><AppContainer><TalentPoolDetail
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/retentions"
                           element={<LazyComponent><Session role={"retention"}><AppContainer><Retentions
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/retentions/:id/:postmortem_key"
                           element={<LazyComponent><Session role={"retention::write"}>
                               <AppContainer>
                                   <CollaboratorState>
                                    <DetailRetention RollBackBreadCrumbs={RollBackBreadCrumbs}/>
                                   </CollaboratorState>
                               </AppContainer></Session></LazyComponent>}/>
                    <Route path="/retentions/add"
                           element={<LazyComponent><Session role={"retention::write"}><AppContainer><AddRetention
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/retentions/add/:postmortem_key"
                           element={<LazyComponent><Session role={"retention::write"}><AppContainer><AddRetention
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/accounts" element={
                        <LazyComponent>
                            <Session role={"account::read"}><AppContainer><Accounts/></AppContainer></Session>
                        </LazyComponent>
                    }/>
                    <Route path="/accounts/:id" element={<LazyComponent><Session role={"account::write"}>
                        <AppContainer><DetailAccountEnvelop
                            RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/business-admin/account/:id/:type" element={<LazyComponent><Session role={"account::write"}>
                        <AppContainer><DetailAccountEnvelop
                            RollBackBreadCrumbs={RollBackBreadCrumbs}/></AppContainer></Session></LazyComponent>}/>
                    <Route path="accounts/:accountKey/projects/:id"
                           element={<LazyComponent><Session role={"account::write"}><AppContainer><ProjectState><DetailProject
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></ProjectState></AppContainer></Session></LazyComponent>}/>
                    <Route path="accounts/new-business/:id"
                           element={<LazyComponent><Session role={"account::write"}><AppContainer><ProjectState><DetailNewBusiness
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></ProjectState></AppContainer></Session></LazyComponent>}/>
                    <Route path="/trainings"
                           element={
                               <LazyComponent><Session><AppContainer><PageCommingSoon/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/assesments"
                           element={
                               <LazyComponent><Session role={"salaries-assessment::read"}><AppContainer><PageCommingSoon/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/requests"
                           element={
                               <LazyComponent><Session><AppContainer><PageCommingSoon/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/settings"
                           element={
                               <LazyComponent><Session><AppContainer><PageCommingSoon/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/business-admin"
                           element={
                               <LazyComponent><Session><AppContainer><PageCommingSoon/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/profile"
                           element={
                               <LazyComponent><Session><AppContainer><PageCommingSoon/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/concept-form"
                           element={
                               <LazyComponent><Session><AppContainer><ConceptForm/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/concept-form/:id"
                           element={
                               <LazyComponent><Session><AppContainer><ConceptForm/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/polls"
                           element={<LazyComponent><Session
                               role={"polls"}><AppContainer><Poll/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/polls/:id"
                           element={<LazyComponent><Session role={"polls::write"}><AppContainer><PollState><AddPoll
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></PollState></AppContainer></Session></LazyComponent>}/>
                    <Route path="/polls/add"
                           element={<LazyComponent><Session role={"polls::write"}><AppContainer><PollState><AddPoll
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></PollState></AppContainer></Session></LazyComponent>}/>
                    <Route path="/polls"
                           element={<LazyComponent><Session
                               role={"polls"}><AppContainer><Poll/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/polls/:id"
                           element={<LazyComponent><Session role={"polls::write"}><AppContainer><PollState><AddPoll
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></PollState></AppContainer></Session></LazyComponent>}/>
                    <Route path="/polls/add"
                           element={<LazyComponent><Session role={"polls::write"}><AppContainer><PollState><AddPoll
                               RollBackBreadCrumbs={RollBackBreadCrumbs}/></PollState></AppContainer></Session></LazyComponent>}/>
                    <Route path="/polls/answers/:id"
                           element={<LazyComponent><AppContainer><PollState><ShowAnswerPoll/></PollState></AppContainer></LazyComponent>}/>
                    <Route path="/polls/show/:id"
                           element={
                               <LazyComponent><AppContainer><PollState><ShowPoll/></PollState></AppContainer></LazyComponent>}/>
                    <Route path="/notifications" element={
                        <LazyComponent><Session><AppContainer><MakerNotificationAlertListEnvelop/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/polls/success-poll" element={<LazyComponent><PageSuccessPoll/></LazyComponent>}/>
                    <Route path="/salary-assessment" element={
                        <LazyComponent><Session role={"salaries-assessment"}><AppContainer><SalaryAssessmentEnvelop/></AppContainer></Session></LazyComponent>}/>
                    <Route path="/salary-assessment/:id" element={
                        <LazyComponent><Session role={"salaries-assessment::write"}><AppContainer><SalaryAssessmentState><SalaryAssessmentDetail
                            RollBackBreadCrumbs={RollBackBreadCrumbs}/></SalaryAssessmentState></AppContainer></Session></LazyComponent>}/>
                    <Route path="/salary-assessment/candidate-list" element={
                        <LazyComponent><Session role={"salaries-assessment::read"}><AppContainer><SalaryAssessmentState><CandidateList
                            RollBackBreadCrumbs={RollBackBreadCrumbs}/></SalaryAssessmentState></AppContainer></Session></LazyComponent>}/>
                    <Route path="/salary-assessment/:id/candidates/:candidateKey" element={
                        <LazyComponent><Session role={"salaries-assessment::write"}><AppContainer><SalaryProposal/></AppContainer></Session></LazyComponent>}/>

                    {/* REFERRALS */}
                    <Route 
                        path="/referrals" 
                        element={ <LazyComponent><Session role={"referrals"}><AppContainer><Referrals/></AppContainer></Session></LazyComponent> }
                    />

                    <Route path="/" element={<Login/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="*" element={<AppContainer><Page404/></AppContainer>}/>
                </Routes>
            </Router>
            <div id='button-float'></div>
        </LoginState>
    );
}

export default App;
