import React from 'react';
import { DataGrid } from 'devextreme-react';
import { ColumnChooser, ColumnFixing, FilterRow, Grouping, GroupPanel, HeaderFilter, Item, Pager, Paging, SearchPanel, Selection, SortByGroupSummaryInfo, StateStoring, Summary, Toolbar, TotalItem } from 'devextreme-react/data-grid';
import { Export } from 'devextreme-react/bar-gauge';
import './PolarisDataGrid.css'

export const PolarisDataGrid = React.forwardRef(({ pages, columnSummaryTotal, data, className, columns, items, id, keyExpr, fileName, storageKey, onExporting = () => {}, onExported = () => {}, onPageIndexChange = () => {}, toolbarHeaders = true,  onCellClick = () => {}, ...props }, ref) => {
  return (
    <DataGrid
      id={id ?? 'grid'}
      ref={ref}
      dataSource={data ?? []}
      keyExpr={keyExpr}
      className={`polaris-datagrid ${className ?? ''}`}
      showBorders={true}
      allowColumnResizing={true}
      allowColumnReordering={true}
      columnResizingMode='widget'
      columnMinWidth={50}
      columnAutoWidth={true}
      focusedRowEnabled={true}
      hoverStateEnabled={true}
      showColumnLines={true}
      rowAlternationEnabled={true}
      onExporting={onExporting}
      onExported={onExported}
      noDataText='No existen datos disponibles.'
      {...props}
    >
      <StateStoring enabled={true} type='localStorage' storageKey={storageKey ?? 'storageKey'}/>
      <FilterRow visible={true} applyFilter='auto'/>
      <HeaderFilter visible={true} texts={{ emptyValue: 'Vacio', cancel: 'Cancelar', ok: 'Aplicar' }}/>
      <GroupPanel visible={toolbarHeaders} emptyPanelText='Arrastra las columnas aqui para agrupar'/>
      <SearchPanel visible={toolbarHeaders} highlightCaseSensitive={false}/>
      <Selection mode="single"/>
      <Grouping autoExpandAll={true}/>
      <ColumnChooser enabled={toolbarHeaders}/>
      <ColumnFixing enabled={true}/>
      <Export 
        enabled={toolbarHeaders} 
        fileName={fileName ?? 'Export Datagrid'} 
        allowExportSelectedData={false}
      />
      <Summary>
        <TotalItem
          column={columnSummaryTotal || keyExpr}
          displayFormat="Total de Registros: {0}"
          summaryType="count"
        />
      </Summary>
      <Paging defaultPageSize={pages ?? 10} onPageIndexChange={onPageIndexChange} />
      {/* <Pager
        visible={true}
        allowedPageSizes={[5, 10, 20, 50, 100, 'all']}
        displayMode={{ text: 'Display Mode \'compact\'', value: 'compact' }}
        showPageSizeSelector={true}
        showInfo={true}
        showNavigationButtons={true} 
      /> */}
      <SortByGroupSummaryInfo summaryItem="count"/>
      {
        toolbarHeaders && (
          <Toolbar>
            <Item
              name="groupPanel"
              locateInMenu="auto"
              location="before"
            />
            {
              items?.map((item) => item)
            }
            <Item
              name="exportButton"
              locateInMenu="auto"
              location="after"
            />
            <Item
              name="columnChooserButton"
              locateInMenu="auto"
              location="after"
            />
            <Item
              name="searchPanel"
              locateInMenu="auto"
              location="after"
            />
          </Toolbar>
        )
      }
      {
        columns?.map((column, index) => {
            return React.cloneElement(column, { key: index })
          }
        )
      }
    </DataGrid>
  )
})